
$moduleNameSpace: dynamic-name-space;
@import '_colors.scss';

[data-sparta-container] .spa-cinema {
  position: relative;
  width: 100%;
  position: relative;

  .spa-cinema-stage {
    border: 1px solid #e3e3e3;
    background: #fcfcfc;


    max-height: 810px;


    display:flex;
    flex-wrap:wrap;
    align-items:flex-start;
    aspect-ratio: 16 / 9;

      &,
    &:hover,
    &:focus {
      outline: 0;
    }
  }

  .spa-cinema-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    order: 1;
  }

  .kaltura-player-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
    outline: none;
    user-select: none;
  }


  .spa-cinema-thumbnail-link {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


    margin: 0;
    padding: 0;
    background: none;

    &:hover,
    &:focus {
      .spa-cinema-control--play-btn {
        border-color:rgba(255,255,255,0.4);
      }
    }
  }


  .spa-cinema-thumbnail--large {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
    transition: .35s all ease-in-out;
  }


  .spa-cinema-control--play-btn {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 108px;
    width: 108px;
    border: 2px solid rgba(255,255,255,0.2);
    background-color: rgba(0,0,0,0.5);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    border-radius: 54px;
    padding: 20px;
    cursor: pointer;

    .playkit-icon {
      display: inline-block;
      font-size: 0;
      width: 100%;
      height: 100%;
      min-height: 64px;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIGZpbGwtcnVsZT0ibm9uemVybyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTc5Ni44MDYgNDYxLjIwMmM0NC45MTkgMjguMDc1IDQ0LjczOSA3My43MDYgMCAxMDEuNjY4bC00NTkuNDcyIDI4Ny4xNzFjLTQ0LjkxOSAyOC4wNzUtODEuMzM0IDcuOTE1LTgxLjMzNC00NS4zMDV2LTU4NS40YzAtNTMuMDk2IDM2LjU5NS03My4yNjYgODEuMzM0LTQ1LjMwNWw0NTkuNDcyIDI4Ny4xNzF6Ii8+PC9zdmc+');
    }


  }




  .spa-cinema-video-transcript-trigger {
    position: relative;
    display: block;
    margin-top: 16px;
    padding-left: 28px;
    transition: .35s all ease-in-out;

    .spa-icon {
      transition: .35s all ease-in-out;
      border: none;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 0;
      height: 24px;
      width: 24px;
      border: 0;

      svg {
        transition: .35s all ease-in-out;
        transform: rotate(90deg);
      }

      path {
        fill: $color-royal-one;
      }
    }

    &:hover,
    &:focus {
      color: #012169;
      .spa-icon {
        path {
          fill: $color-brand-secondary;
        }
      }
    }
  }


  .spa-cinema-video-transcript-content {
    position: relative;
    transition: all .75s ease-in-out;
    overflow: hidden;
    height: 0;
    padding-top: 12px;
  }


  .spa-cinema-video-transcript--hidden {
    .spa-cinema-video-transcript-content {
      display: none;
    }
  }


  .spa-cinema-video-transcript--active {
    .spa-cinema-video-transcript-content {
      display: block;
    }

    .spa-cinema-video-transcript-trigger {
      .spa-icon {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
}